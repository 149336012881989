<template>
    <div id="1_signup_container" class="vx-row no-gutter justify-around p-16">
        <div v-if="!hasToSelectAccountType" id="offset-register"
            class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
            <img width="100%" height="100%" src="https://cdn.redgirasol.com/nuevo_portal/fotografias/supplier-onboarding.png" alt="">
        </div>
        <div v-if="!hasToSelectAccountType" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
            <vx-card class="login-card py-3 px-3">
                <div slot="no-body" class="">
                    <div class="vx-col sm:w-full">
                        <div class="px-8 pt-8 login-tabs-container">
                            <div class="vx-row m-0">
                                <div class="vx-col w-full p-0" style="min-height: 200px">
                                    <div class="px-6 pt-5 pb-5">
                                        <!-- ENCABEZADO -->
                                        <div class="vx-row mb-4">
                                            <div class="vx-col w-full">
                                                <p class="text-2xl card-sub-title">Crea tu usuario para ser integrador de RedGirasol.</p>
                                            </div>
                                        </div>
                                        <!-- REFERAL CODE MESSAGE -->
                                        <div v-if="fromReferal">
                                            <vs-alert class="mb-3" icon-pack="feather" icon="icon-info" style="height:auto"
                                                color="rgb(41, 147, 138)">
                                                Estás utilizando el código de invitación <span class="bold">{{
                                                    referalInfo.uri }}</span>.
                                            </vs-alert>
                                        </div>
                                        <!-- END REFERAL CODE MESSAGE -->
                                        <!-- CONTENIDO -->
                                        <div class="main-form">
                                            <div class="vx-row">
                                                <div :class="colClass">
                                                    <vs-input class="w-full" label="Nombre de usuario" name="alias"
                                                        v-validate="requiredRules" v-model.lazy="onboardingUserData.alias"
                                                        :danger="hasError('alias')"
                                                        danger-text="El campo es obligatorio. Escribe un alias o nombre de usuario con el que te gustaría ser 
                                        identificado en la plataforma, este lo podrás utilizar después para ingresar a RedGirasol"
                                                        :success="isSuccess('alias')" icon-pack="feather"
                                                        :placeholder="requiredPlaceholder" />
                                                    <vs-input-hint>El nombre de usuario puede contener letras y
                                                        números</vs-input-hint>
                                                </div>
                                                <div :class="colClass">
                                                    <vs-input class="w-full" label="Correo electrónico" name="email"
                                                        v-validate="emailRules" v-model.lazy="onboardingUserData.email"
                                                        :danger="hasError('email')" :danger-text="errorText('email')"
                                                        :success="isSuccess('email')" icon-pack="feather"
                                                        :placeholder="emailPlaceholder" />
                                                </div>
                                                <div :class="colClass">
                                                    <label class="vs-input--label">Celular a 10 dígitos</label>
                                                    <div class="flex">
                                                        <vue-country-code name="dialCode" class="vs-custom"
                                                            data-vv-name="dialCode" :enableSearchField="true"
                                                            searchPlaceholderText="Buscar código de país"
                                                            :enabledCountryCode="true" @onSelect="onSelect"
                                                            :preferredCountries="['MX']" :danger="hasError('dialCode')"
                                                            :danger-text="errorText('dialCode')"
                                                            :success="isSuccess('dialCode')">
                                                        </vue-country-code>
                                                        <vs-input class="w-full" label="" name="phone" type="tel"
                                                            v-validate="'required|numeric'"
                                                            v-model.lazy="onboardingPhoneData.phone"
                                                            :danger="hasError('phone')" :danger-text="errorText('phone')"
                                                            :success="isSuccess('phone')" icon-pack="feather"
                                                            placeholder="(Requerido) Ingresa 10 dígitos" />
                                                    </div>
                                                </div>
                                                <div :class="colClass">
                                                    <label class="vs-input--label">Contraseña *</label>
                                                    <vx-input-group class="w-full">
                                                        <vs-input v-model.lazy="onboardingUserData.password"
                                                            v-validate="requiredRules" :danger="hasError('password')"
                                                            :danger-text="errorText('password')"
                                                            :success="passwordFieldValid" :type="passInputType"
                                                            :placeholder="requiredPlaceholder" name="password" />

                                                        <template slot="append">
                                                            <div class="append-text btn-addon" style="height:40px;">
                                                                <vs-button color="dark" @click="togglePassInputType()"
                                                                    icon-pack="feather" :icon="passInputIcon"></vs-button>
                                                            </div>
                                                        </template>
                                                    </vx-input-group>
                                                </div>
                                                <div v-if="onboardingUserData.password != null && onboardingUserData.password != ''"
                                                    :class="colClass">
                                                    <div class="vx-row">
                                                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                            <p>
                                                                <feather-icon :svgClasses="`${colorForCriteria(0)} h-4 w-4`"
                                                                    class="p-2" :icon="iconForCriteria(0)" />Una letra
                                                                minúscula <br>
                                                                <feather-icon :svgClasses="`${colorForCriteria(1)} h-4 w-4`"
                                                                    class="p-2" :icon="iconForCriteria(1)" />Una letra
                                                                mayúscula <br>
                                                                <feather-icon :svgClasses="`${colorForCriteria(2)} h-4 w-4`"
                                                                    class="p-2" :icon="iconForCriteria(2)" />Un número <br>
                                                                <feather-icon :svgClasses="`${colorForCriteria(3)} h-4 w-4`"
                                                                    class="p-2" :icon="iconForCriteria(3)" />Un símbolo <br>
                                                            </p>
                                                        </div>
                                                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                                                            <p>
                                                                <feather-icon :svgClasses="`${colorForCriteria(4)} h-4 w-4`"
                                                                    class="p-2" :icon="iconForCriteria(4)" />8 caracteres
                                                                mínimo <br>
                                                                <feather-icon :svgClasses="`${colorForCriteria(5)} h-4 w-4`"
                                                                    class="p-2" :icon="iconForCriteria(5)" />Sin letras ni
                                                                números consecutivos <br>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :class="colClass">
                                                    <label class="vs-input--label">Confirma la contraseña *</label>
                                                    <vx-input-group class="w-full">
                                                        <vs-input onpaste="return false;"
                                                            v-model.lazy="onboardingUserData.confirmed_password"
                                                            v-validate="requiredRules"
                                                            :danger="hasError('confirmed_password')"
                                                            :danger-text="errorText('confirmed_password')"
                                                            :success="confirmPasswordFieldValid"
                                                            :type="confirmPassInputType" :placeholder="requiredPlaceholder"
                                                            name="confirmed_password" />
                                                        <template slot="append">
                                                            <div class="append-text btn-addon" style="height:40px;">
                                                                <vs-button color="dark"
                                                                    @click="toggleConfirmPassInputType()"
                                                                    icon-pack="feather"
                                                                    :icon="confirmPassInputIcon"></vs-button>
                                                            </div>
                                                        </template>
                                                    </vx-input-group>
                                                </div>
                                                <div :class="colClass">
                                                    <div class="vx-col w-full mb-2">
                                                        <label class="vs-input--label">¿Cómo te enteraste de
                                                            RedGirasol? *</label>
                                                        <vs-select label="" name="source" v-validate="requiredRules"
                                                            :danger="hasError('source')"
                                                            :danger-text="errorText('source')"
                                                            :success="isSuccess('source')"
                                                            placeholder="Selecciona una opción."
                                                            v-model.lazy="onboardingUserData.source"
                                                            class="mr-2 w-full">
                                                            <vs-select-item v-for="(item, index) in sourceOptions"
                                                                :text="item.label" :key="index"
                                                                :value="item.value"></vs-select-item>
                                                        </vs-select>
                                                    </div>
                                                    <div :class="colClass" v-if="onboardingUserData.source === 'other'">
                                                        <vs-input class="w-full" label="" name="other_source"
                                                            v-validate="requiredRules"
                                                            v-model.lazy="onboardingUserData.other_source"
                                                            :danger="hasError('other_source')"
                                                            :danger-text="errorText('other_source')"
                                                            :success="isSuccess('other_source')" icon-pack="feather"
                                                            placeholder="¿Cómo?" />
                                                    </div>
                                                </div>
                                            </div>
                                            <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle"
                                                class="mb-5" color="danger">
                                                <span class="font-regular">{{ errorMssg }}</span>
                                            </vs-alert>
                                            <div class="vx-row">
                                                <div class="footer-register vx-col w-full">
                                                    <vs-button id="1_signup_confirm_btn" size="large"
                                                        class="w-full ml-auto mt-2 vs-button-dark"
                                                        @click="saveOnboardingInitalStep()">Crear cuenta</vs-button>
                                                    <p class="text-center mt-5">
                                                        Al dar clic en "Crear cuenta" estarás aceptando
                                                        los <a class="inline-link" target="_blank" rel="noopener"
                                                            :href="$sanitizeUrl(terminosCondicionesUrl)"> Términos y
                                                            Condiciones</a> y
                                                        el <a class="inline-link" target="_blank" rel="noopener"
                                                            :href="$sanitizeUrl(avisoPrivacidadUrl)">Aviso de Privacidad</a>
                                                        de RedGirasol.
                                                    </p>
                                                    <h5 class="text-center mt-5">
                                                        ¿Ya tienes una cuenta? <span><router-link
                                                                class="bold ml-1 inline-link-primary text-dark" to=""
                                                                @click.native="goToLogin">Da clic aquí.</router-link></span>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- END MAIN FORM DIV -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
        <div v-if="hasToSelectAccountType" class="vx-col sm:w-5/6 md:w-2/3 lg:w-2/3 xl:w-4/5 xxl:w-2/3 m-0 align-center">
            <vx-card>
                <vs-progress class="mb-base" :percent="15" :height="10" color="primary"></vs-progress>
                <h3 class="mb-base">¿Cómo opera tu negocio?</h3>
                
                <div class="account-type-selector mb-8">
                    <vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mb-5" color="danger">
                        <span class="font-regular">{{ errorMssg }}</span>
                    </vs-alert>
                    <div class="vx-col w-full mb-8 account-button" v-for="(item, index) in accountTypeList" :key="index">
                        <rg-radio class="w-full" groupName="accountType" :label="item.label" :value="item.value"
                            v-model="accountType" />
                    </div>
                </div>

                <div class="flex flex justify-center m-20">
                    <vs-button type="border" class="sm:w-1/2 md:w-1/5 mt-2 vs-button-dark"
                        @click="hasToSelectAccountType = false">Atrás</vs-button>
                    <vs-button class="sm:w-1/2 md:w-1/5 mt-2 ml-10 vs-button-dark"
                        @click="saveOnboardingInitalStep(true)">Continuar</vs-button>
                </div>
            </vx-card>
        </div>
    </div>
</template>
  
<script>
import { mapActions, mapMutations } from 'vuex'
import sessionsHelper from "@mixins/sessionsHelper";
import inputHelper from "@mixins/inputHelper";
import VsInputHint from '@components/vsInput/VsInputHint.vue';
const textType = "text";
const iconEye = "icon-eye";
const iconEyeOff = "icon-eye-off";
const sourceOptions = [
    { label: "Me platicó un amigo/conocido/familiar", value: 'familiar' },
    { label: "Google u otro motor de búsqueda", value: 'search_engine' },
    { label: "Redes sociales (Facebook, Youtube, Twitter, etc.)", value: 'social_networks' },
    { label: "Nota en periódico/revista/blog", value: 'publication' },
    { label: "Referido por promotor", value: 'promotor' },
    { label: "Simulación portal publico", value: 'simulation' },
    { label: "Otro", value: 'other' },
]

const accountTypes = [
    { label: "Persona moral", value: "PM" },
    { label: "Persona física con actividad empresarial", value: "PFAE" }
]

export default {
    name: "SupplierOnboardingRegisterPage",
    props: {
        referalInfo: {
            type: Object,
            default: () => null
        },
        type: {
            type: String
        },
    },
    mixins: [inputHelper, sessionsHelper],
    components: {
        VsInputHint
    },
    data() {
        return {
            sourceOptions: sourceOptions,
            // imgInvestorUrl: 'https://cdn.redgirasol.com/img/icons/A-02.png',
            // investorContractUrl: 'contrato-de-comision-mercantil-inversionista',
            requiredRules: 'required',
            errorMssg: null,
            contracts: {
                allContracts: null,
            },
            colClass: "vx-col w-full mb-5",
            requiredPlaceholder: '(Requerido)',
            emailRules: 'required|email',
            emailPlaceholder: 'ejemplo@mail.com',
            passInputPassMode: true,
            confirmPassInputPassMode: true,
            criteria: [false, false, false, false, false, false, false],
            passwordError: null,
            onboardingUserData: {
                alias: null,
                email: null,
                password: null,
                confirmed_password: null,
                source: null,
                other_source: null,
            },
            onboardingPhoneData: {
                country_calling_code: null,
                phone: null,
            },
            accountType: null,
            payload: {},
            hasToSelectAccountType: false,
            accountTypeList: accountTypes

        }
    },
    computed: {
        terminosCondicionesUrl() {
            return `${this.ApiDomain}/terminos-y-condiciones`;
        },
        avisoPrivacidadUrl() {
            return `${this.ApiDomain}/aviso-de-privacidad`;
        },
        // comisionMercantilUrl() {
        //     return `${this.ApiDomain}/${this.investorContractUrl}`;
        // },
        passInputType() {
            return this.passInputPassMode ? "password" : textType;
        },
        passInputIcon() {
            return this.passInputPassMode ? iconEye : iconEyeOff;
        },
        confirmPassInputType() {
            return this.confirmPassInputPassMode ? "password" : textType;
        },
        confirmPassInputIcon() {
            return this.confirmPassInputPassMode ? iconEye : iconEyeOff;
        },
        passwordFieldValid() {
            return this.testPassword(this.onboardingUserData.password);
        },
        confirmPasswordFieldValid() {
            return this.onboardingUserData.password === this.onboardingUserData.confirmed_password && this.onboardingUserData.confirmed_password != null;
        },
        fromReferal() {
            return this.referalInfo ? true : false;
        },
        referalCode() {
            return this.referalInfo ? this.referalInfo.uri : null;
        }
    },
    methods: {
        ...mapActions('auth', ['getUserInfo']),
        ...mapMutations('auth', ['setStateAccessToken']),
        ...mapMutations('extended_auth', ['set_new_extended_session', 'set_extended_session_token', 'set_extended_session_role', 'set_extended_session_user']),
        onSelect({ dialCode }) {
            this.onboardingPhoneData.country_calling_code = `+${dialCode}`;
        },
        // SE GUARDA EL STEP INICIAL
        async saveOnboardingInitalStep(isReady = false) {
            this.errorMssg = null;
            if (!await this.validateForm()) {
                return;
            }
            try {
                this.payload = {
                    user: this.onboardingUserData,
                    phone_data: this.onboardingPhoneData,
                    from_referal: this.fromReferal,
                    referal_code: this.referalCode,
                };

                if (isReady) {
                    if (this.accountType != null) {
                        this.showLoading(true, 'Realizando tu registro...')
                        this.payload.person_type = this.accountType;
                        await this.publicApiPost('/api/v2/supplierOnboarding/new', this.payload);
                        await this.doLogin();
                        this.showLoading(false);
                    } else {
                        this.errorMssg = "Para continuar, selecciona la opción que mejor describa tu negocio."
                    }
                } else {
                    // Mandamos a seleccionar su tipo de persona. 
                    this.hasToSelectAccountType = true;
                }


            }
            catch (e) {
                this.showError(e);
                this.showLoading(false);
            }
        },
        showError(e) {
            this.warn(e)
            let err = "Ha ocurrido un error con la operación, intente más tarde";
            if (e.response && e.response.data && e.response.data.error) {
                err = e.response.data.error;
            }
            this.errorMssg = err;
            this.errorNotif("Error en el registro", err, 10000);
        },
        async goToLogin() {
            await this.$router.replace({ name: 'login' });
        },
        async validateForm() {
            this.errorMssg = null;
            let r1 = await this.$validator.validateAll();
            if (r1) {
                return this.validatePassword();
            }
            return false;
        },
        colorForCriteria(idx) {
            return this.criteria[idx] ? 'text-success' : 'text-danger';
        },
        iconForCriteria(idx) {
            return this.criteria[idx] ? 'CheckCircleIcon' : 'CircleIcon';
        },
        completePasswordForm() {
            return this.onboardingUserData.password != null && this.onboardingUserData.password.length >= 8;
        },
        completeConfirmPassword() {
            return this.onboardingUserData.confirmed_password != null && this.onboardingUserData.confirmed_password.length >= 8;
        },
        validatePassword() {
            if (this.onboardingUserData.password !== this.onboardingUserData.confirmed_password) {
                this.errorMssg = "Las contraseñas no coinciden";
            }
            else {
                if (this.testPassword(this.onboardingUserData.password)) {
                    return true;
                }
                else {
                    this.errorMssg = "La contraseña no cumple los requerimientos";
                }
            }
            return false;
        },
        testPassword(pass) {
            if (pass === null || pass === undefined) {
                this.criteria[0] = false;
                this.criteria[1] = false;
                this.criteria[2] = false;
                this.criteria[3] = false;
                this.criteria[4] = false;
                this.criteria[5] = false;
                this.criteria[6] = false;
                return false;
            }
            //let pass = this.pw1
            const number = /.*[0-9].*/
            const lower = /.*[a-z].*/
            const upper = /.*[A-Z].*/
            const special = /.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*/
            const consecutive = ['abcd', 'bcde', 'cdef', 'defg', 'efgh', 'fghi', 'ghij', 'hijk', 'ijkl', 'jklm', 'klmn', 'lmnñ', 'mnño', 'nñop', 'ñopq', 'opqr', 'pqrs', 'qrst', 'rstu', 'stuv', 'tuvw', 'uvwx', 'vwxy', 'wxyz',
                '0123', '1234', '2345', '3456', '4567', '5678', '6789', '7890', '8901', '9012', '2109', '1098', '0987', '9876', '8765', '7654', '6543', '5432', '4321', '3210',
                'aaaa', 'bbbb', 'cccc', 'dddd', 'eeee', 'ffff', 'gggg', 'hhhh', 'iiii', 'jjjj', 'kkkk', 'llll', 'mmmm', 'nnnn', 'ññññ', 'oooo', 'pppp', 'qqqq', 'rrrr', 'ssss', 'tttt', 'uuuu', 'vvvv', 'wwww', 'xxxx', 'yyyy', 'zzzz',
                '1111', '2222', '3333', '5555', '6666', '7777', '8888', '9999',
                '!!!!', '""""', '####', '$$$$', '%%%%', '&&&&', '((((', '))))', '****', '++++', ',,,,', '----', '....', '////', '::::', ';;;;', '<<<<', '====', '>>>>',
                '????', '@@@@', '[[[[', ']]]]', '^^^^', '____', '````', '{{{{', '||||', '}}}}', '~~~~']

            this.criteria[0] = lower.test(pass);
            this.criteria[1] = upper.test(pass);
            this.criteria[2] = number.test(pass);
            this.criteria[3] = special.test(pass);
            this.criteria[4] = pass.length > 7;
            let no_consecutive = true
            for (let i = 0; i < consecutive.length; i++) {
                if (pass.toLowerCase().includes(consecutive[i])) {
                    no_consecutive = false
                }
            }
            this.criteria[5] = no_consecutive

            return (this.criteria[0] && this.criteria[1] && this.criteria[2] && this.criteria[3] && this.criteria[4] && this.criteria[5]);
        },
        togglePassInputType() {
            this.passInputPassMode = !this.passInputPassMode;
        },
        toggleConfirmPassInputType() {
            this.confirmPassInputPassMode = !this.confirmPassInputPassMode;
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return (ff && ff.required && ff.valid);
        },
        async doLogin() {
            this.showLoading(true);
            try {
                const payload = { email: this.onboardingUserData.email, password: this.onboardingUserData.password, roleName: "investor", emailRemember: false, rememberme: false, twoFactorCode: "" };
                const res = await this.publicApiPost('api/v1/auth/loginViaApp', payload);
                this.$mixpanel.track('login', {
                    email: this.email
                })
                const token = res.data.access_token;
                const loggerRoleName = res.data.logged_role_name;
                // almacenar el token en auth (sessionStorage)
                this.setStateAccessToken(token);

                window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                this.$store.state.isUnauthenticated = false;
                const userRes = await this.getUserInfo(loggerRoleName);
                // verificar si debe almacenarse la sesion extendida
                this.checkForExtendedSession(token, userRes.data);
                this.showFormPrompt = false;

                // redirigir a la vista original a la que el usuario intento entrar
                if (this.$route.query && this.$route.query.redirect) {
                    const rep = this.$route.query.redirect;
                    await this.$router.replace(rep || '/');
                }
                else {
                    await this.$router.replace({ name: 'home' });
                }
            }
            catch (error) {
                this.onError = true;
                console.log(error)
                if (error && error.response && error.response.data && error.response.data.message) {
                    this.errorMssg = error.response.data.message;

                    if (error.response.data.locked) {
                        this.isLocked = (error.response.data.locked === "true" || error.response.data.locked === true);
                    }
                    if (error.response.data.extra && error.response.data.extra === this.ACCOUNT_NOT_ACTIVATED) {
                        this.canRequestNewActivationMail = true;
                    }
                }
                else {
                    this.errorMssg = "Ha ocurrido un error. Intenta por favor más tarde.";
                }
                this.warn(error);
            }
            this.showLoading(false);
        },
        checkForExtendedSession(token, user) {
            if (this.roleShouldHaveExtendedSession(user.role)) {
                // almacenar el token y los datos de la sesion en extended_auth (localStorage)
                this.set_new_extended_session(user.user_id);
                this.set_extended_session_token(token);
                this.set_extended_session_role(user.role);
                this.set_extended_session_user(user);
            }
        },
    }
}
</script>
<style scoped>
.footer-prompt {
    padding: 1rem 0 0 0 !important;
}

.footer-register a {
    color: #28DE18 !important;
}

.account-type-selector {
    margin-left: 35%;
    margin-right: 35%;
}
@media (max-width: 1000px){
    #offset-register {
        display: none;
    }
}
</style>